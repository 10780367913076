<template>
  <div class="notification-details py-8">
    <NotificationAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="notification"
        name="notification"
        redirect="/notifications"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="notification-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="notification-details__divider"></v-divider>

            <NotificationForm :notification="notification" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Notification
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import NotificationForm from '@/views/Home/Notifications/components/NotificationForm'
import NotificationAppbar from '@/views/Home/Notifications/components/NotificationAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'NotificationDetails',

  components: {
    ViewingTitle,
    NotificationAppbar,
    NotificationForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      notification: (state) => state.notification.notificationDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.notification ? this.notification.name : null
    },

    hasDeletePermission() {
      return validatePermissions(
        [PERMISSION.NOTIFICATIONS_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getNotificationDetails: 'notification/getNotificationDetails',
    }),

    ...mapMutations({
      clearNotificationDetails: 'notification/clearNotificationDetails',
    }),

    async getNotification() {
      this.loading = true
      await this.getNotificationDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getNotification()
  },

  destroyed() {
    this.clearNotificationDetails()
  },

  watch: {
    $route() {
      this.getNotification()
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
